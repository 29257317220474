@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

body, #root {
    overscroll-behavior: contain;
    font-family: 'Montserrat', sans-serif;
    height: calc(100vh - env(safe-area-inset-top, 0px) - env(safe-area-inset-bottom, 0px))
}

header {
    padding: 0 !important;
}

button {
    border: #1c1c1c 1.5px solid !important;
}

.no-border {
    border: 0 !important;
}

.container {
    max-width: 100vw !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
}

p,
a,
span {
    font-family: 'Montserrat', sans-serif;
}

input,
textarea {
    font-family: "Montserrat", sans-serif;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
    display: none !important;
}

input:focus-visible,
textarea:focus-visible {
    outline: 0;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
    height: 0px;
}

/* Hide the default checkbox */
input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border: 2px solid #72517e;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

input[type='checkbox']:checked {
    background-color: #72517e;
}

input[type='checkbox']:checked::before {
    content: ' ';
    background-image: url('./images/checked.png');
    position: absolute;
    top: 3.64px;
    left: 2px;
    width: 12px;
    height: 8.72px;
    border-radius: 2px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #72517e;
}

/** MATERIAL UI **/
.MuiPickersCalendarHeader-root {
    position: relative;
    padding: 0 16px;
}

.MuiPickersArrowSwitcher-root {
    display: flex;
    width: 100vw;
    justify-content: space-between;
}

.MuiButtonBase-root {
    border: none !important;
}

.MuiPickersDay-today {
    border: 0.5px solid #c1c1c1 !important;
}

.MuiPickersCalendarHeader-labelContainer {
    position: absolute;
    left: 30%;
    right: 30%;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
}

.MuiDayCalendar-header {
    justify-content: space-between !important;
}

.MuiPickersCalendarHeader-label,
.MuiDayCalendar-header {
    margin: 0 !important;
}

.MuiButtonBase-root-MuiPickersDay-root {
    border-radius: 0 !important;
}

.MuiPickersDay-root.Mui-selected,
.root.Mui-selected {
    background-color: #b788d6 !important;
    color: #ffffff !important;
    border-radius: 50px !important;
}

.MuiPickersDay-root,
.MuiButtonBase-root-MuiPickersDay-root,
.MuiPickersDay-root,
.MuiPickersDay-root,
.MuiTypography-root-MuiDayCalendar-weekDayLabel,
.MuiButtonBase-root-MuiPickersDay-root {
    margin: 0 auto !important;
}

.MuiPickersCalendarHeader-label {
    font-family: 'Inter', sans-serif !important;
    font-size: 18px;
}

.MuiTypography-root-MuiDayCalendar-weekDayLabel,
.MuiButtonBase-root-MuiPickersDay-root,
.MuiButtonBase-root-MuiPickersDay-root {
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    border-radius: 50px !important;
}

.MuiDateCalendar-root {
    width: 90vw !important;
    max-width: 400px;
    padding: 16px 0;
}

.MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
.MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
    border: 1px solid #d9d9d9 !important;
    background: #fff !important;
    border-radius: 50% !important;
}

.hidde-clock::-webkit-calendar-picker-indicator {
    display: none;
}

.pac-item {
    z-index: 99 !important;
}

.MuiInputBase-root {
    border-radius: 100px !important;
    padding: 0.75rem 1.5rem !important;
    border: 1px solid #1c1c1c !important;
}

.MuiInputBase-input {
    position: relative !important;
    padding: 0 !important;
    overflow: hidden;
}

.MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.MuiFormLabel-root {
    position: relative !important;
    transform: none !important;
    padding-left: 14px !important;
}

.MuiInputAdornment-root .MuiButtonBase-root {
    position: absolute;
    width: 100%;
    left: 0;
    color: transparent !important;
}

.Mui-error {
    color: #1c1c1c !important;
}

.MuiDialogContent-root .MuiPickersToolbar-root,
.MuiDialogContent-root .MuiPickersLayout-contentWrapper,
.MuiDialogContent-root .MuiDateCalendar-root {
    width: calc(100vw - 64px) !important;
}

/** SWIPER/REACT **/
#home.swiper {
    width: 362px !important;
    max-width: 88vw !important;
}

#home .swiper-slide {
    width: 173px !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.swiper-pagination-bullet {
    background-color: #DFCDEA;
    height: 15px;
    width: 15px;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
    background-color: #72517e;
    height: 15px;
    width: 33px;
    border-radius: 50px;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active-prev,
.swiper-pagination-bullet.swiper-pagination-bullet-active-prev-prev,
.swiper-pagination-bullet.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet.swiper-pagination-bullet-active-next-next {
    transform: none;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination-bullets-dynamic {
    width: 200px !important;
}

/* Custom animation */
@keyframes backgroundFade {
    0% {
        background-size: 0%;
    }

    100% {
        background-size: 200%;
    }
}

.background-fade {
    background-image: radial-gradient(circle, #F8E7FF 80%, #F5F0F7 10%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0%;
    animation: backgroundFade 300ms forwards;
}

.background-fade-reverse {
    background-image: radial-gradient(circle, #F8E7FF 80%, #F5F0F7 10%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0%;
    animation: backgroundFade 300ms reverse;
}

.specialities {
    display: grid;
    grid-template-columns: min-content;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
    display: none;
}